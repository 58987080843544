// test5

<template>
  	<v-container fluid>
    <v-row>
        <v-col cols="3">
		<v-card class="grey darken-3 overflow-hidden">
			<v-app-bar
				absolute
				dark dense
				scroll-target="#playground"
				color="blue-grey darken-3"
				elevate-on-scroll
			>
				<v-toolbar-title></v-toolbar-title>
			</v-app-bar>
			<v-sheet id="playground" class="grey darken-3 overflow-y-auto" max-height="600">
				<v-container fluid class="ma-0 pa-0 mt-12" style="height: 1500px;padding-top: 50px;"
				 @drop.stop="drop($event, 1)" @dragover.prevent
				>
					<v-row v-for="(item, index) in aItems" :key="index" class="ma-0 pa-0">
						<v-col cols="12" class="ma-0 pb-0 pt-2 px-2">
							<v-card class="elevation-1 grey darken-2 ma-0 px-1"
								@mousedown="dragEnable" style="cursor: move;"
								:draggable="dragEnabled"
								@dragover.prevent
								@dragstart="dragstart(item, index, 1, $event)"
								@dragend="dragend(item, index, $event)"
							>{{ item.name }}</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-sheet>
		</v-card>
        </v-col>
        <v-col cols="9" class="pl-0">
		<v-card class="grey darken-3">
			<v-app-bar absolute dark dense color="blue-grey darken-3">
				<v-toolbar-title>Formular</v-toolbar-title>
				<v-spacer/>
				<v-btn small class="ml-3" color="blue-grey">Config</v-btn>
			</v-app-bar>
			<v-sheet class="grey darken-3 ma-0 pa-1" height="600">
				<v-container fluid style="height: 500px;padding-top: 60px;"
				 @drop.stop="drop($event, 2)" @dragover.prevent
				>
					<v-row justify="start" align-content="stretch">
						<v-col v-for="(item, index) in bItems" :key="index" class="ma-0 pa-1" :cols="item.width">
							<v-card class="elevation-1 grey darken-2 ma-0 pa-3"
								@mousedown="dragEnable" style="cursor: move;"
								:draggable="dragEnabled"
								@dragover.prevent
								@dragstart="dragstart(item, index, 2, $event)"
								@dragend="dragend(item, index, $event)"
								@dragenter.prevent="dragenter(item, index, $event)"
								@mouseenter.prevent="hoverenter(item, index, $event)"
								@mouseleave.prevent="hoverleave(item, index, $event)"
							>{{ item.name }}</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-sheet>
		</v-card>
        </v-col>
    </v-row>
	</v-container>
</template>

<script>
	export default {
		name: 'test5',
		components: {},
	  	props: {
	  	},
		data: () => ({
			items: [
				{ name: 'D', pos: 4, width: 4, color: 'white', },
				{ name: 'B', pos: 2, width: 4, color: 'white', },
				{ name: 'A', pos: 1, width: 4, color: 'white', },
				{ name: 'F', pos: 0, width: 4, color: 'white', },
				{ name: 'E', pos: 0, width: 4, color: 'white', },
				{ name: 'C', pos: 3, width: 5, color: 'white', },
			],
			dragEnabled: false,
			dragItem: {},
			dragItemIdx: null,
			dragEnterIdx: null,
			dragCtx: null,
			hoverItem: {},
		}),
		computed: {
            aItems: function () {
                var self = this
                if(!self.items.length) {return []}
                return self.items.filter(e => {
                    return (!e.pos)
                })
            },
            bItems: function () {
                var self = this
                if(!self.items.length) {return []}
                return self.items.filter(e => {
                    return (!!e.pos)
                })
            },
		},
		mounted() {
			this.sortItems()
			window.addEventListener('keyup', this.keyMonitor)
		},
		beforeDestroy() {
			window.removeEventListener('keyup', this.keyMonitor);
		},
		methods: {
			dragEnable() {
				this.dragEnabled = true
			},
			dragstart(item, idx, ctx) {
				if(this.dragEnabled && item) {
					this.dragItem = item
					this.dragItemIdx = idx
					this.dragCtx = ctx
					//console.log('dragstart', item.name, 'idx:', idx)
				}
			},
			dragend(item, idx) {
				if(this.dragEnabled && item) {
					this.dragEnabled = false
					this.dragItem = {}
					this.dragCtx = 0
					//console.log('dragend', item.name, 'idx:', idx)
				}
			},
			dragenter(item, idx, ctx) {
				if(this.dragCtx == 2) {
					if(this.dragEnabled && item && item.name != this.dragItem.name) {
						//console.log('dragenter', item.name, 'idx:', idx)
						//const drapItemPos = this.dragItem.pos
						if(this.dragEnterIdx + 1 == idx || this.dragItemIdx + 1 == idx) {this.dragItem.pos = item.pos + 5}
						else {this.dragItem.pos = item.pos - 5}
						//item.pos = drapItemPos
						this.sortItems()
						//this.dropIdx = idx
						this.dragEnterIdx = idx
					}
				}
			},
            drop(e, ctx) {
				if(this.dragCtx != ctx) {
					if(ctx == 1) {this.dragItem.pos = 0} else {this.dragItem.pos = 999}
					this.dragCtx = 0
					this.sortItems()
				}
            },
			sortItems() {
				this.items.sort((a, b) => {
					//return (a.pos || b.pos) ? a.pos - b.pos : a.order - b.order
					return (a.pos || b.pos) ? a.pos - b.pos : (a.name < b.name) ? -1 : 1
				})
				this.items.forEach((e, index) => {
					if(e.pos) {e.pos = index * 10 + 10;}
				})
				//console.log(this.items)
			},
			hoverenter(item, idx) {
				item.color='black'
				this.hoverItem = item
			},
			hoverleave(item, idx) {
				item.color='white'
				this.hoverItem = null
			},
			keyMonitor(e) {
				//console.log('Key:', e)
				if(this.hoverItem) {
					if(e.key == 'ArrowLeft') {
						this.hoverItem.width -= 1
					}
					if(e.key == 'ArrowRight') {
						this.hoverItem.width += 1
					}
					//console.log('new width:', this.hoverItem.width)
				}
			},
		},
		watch: {
        },
	}
</script>

<style>
</style>